body {
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1440px; 
	margin: 0 auto !important; 
	float: none !important; 

}

h1,h2,h3,h4{
  color: rgba(0,0,0,.8);
  font-family: 'Roboto', sans-serif;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #4F4F4F;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
}

.App-nav {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  background-color: #7C4CFF;
  a {
    padding-right: 55px;
    text-decoration: none;
    font-weight: 600;
    color: rgba(255,255,255,.7);
    svg{
      padding-right: 8px;
    }
  
  }
}

a {
  padding-right: 55px;
  text-decoration: none;
  font-weight: 600;
  color: rgba(255,255,255,.7);
  svg{
    padding-right: 8px;
  }

}

.selected{
  color: red !important;
}

.App-profile {
 position: absolute;
 right: 0,
}

.App-grid{
  display: grid; 
  grid-template-columns: 2fr 1fr; 
  gap: 0px 30px; 
}

.App-grid-container{
    margin: 0 auto; 
}

.Card{
  background-color: white;
  border-radius: 8px;

  padding: 20px;
}


.course-card{
  box-shadow:0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  padding: 20px;
  display: flex;
  border-radius: 5px;
 
  cursor: pointer;
  img{
    object-fit: cover;
  }
}

.completed-list{
  ul{
    color:white
  }
  li{
    text-decoration: line-through;
  }
}


/* CSS */
button {
  margin: 10px;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid  #ff5722;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px  #ff5722, 2px 2px 0px 0px #ff5722, 3px 3px 0px 0px #ff5722, 4px 4px 0px 0px #ff5722, 5px 5px 0px 0px  #ff5722;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  &:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }
  
}


/* CSS */
.button-79 {
  margin:5px;
  backface-visibility: hidden;
  background: #4caf50;
  border: 0;
  border-radius: .375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: .9;
  padding: .5rem 1rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

.button-79:not(:disabled):hover {
  transform: scale(1.05);
}

.button-79:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-79:focus {
  outline: 0 solid transparent;
}

.button-79:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

.button-79:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-79:not(:disabled):active {
  transform: translateY(.125rem);
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  
}

.debug-panel{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  max-height: 300px;

  background-color: #0b0f1aa9;
  color:white;

  .debug-header{
    height: 50px;
    width: 100%;
    background-color: #1c1d1f;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid #7c4cff;
    
  }

  .debug-content{
 
    padding: 50px;
    padding-top: 90px;
    overflow-y: auto; /* Enable scroll if needed */
    overflow-x: hidden;
  }
}